<template>
  <section class="testimonial-sec" :class="{['dark-pg']: isDarkMode}">
    <base-container>
      <footer class="testimonial-sec__footer">
        <h2 class="testimonial-sec__title" :class="{['dark-pg']: isDarkMode}">Referencie</h2>
        <div class="carousel-nav testimonial-sec__nav">
          <button class="carousel-nav__button testimonial-sc__button-prev">
            <svg width="24" height="24">
              <use href="../../assets/icons.svg#chevron-left"></use>
            </svg>
          </button>
          <button class="carousel-nav__button testimonial-sc__button-next">
            <svg width="24" height="24">
              <use href="../../assets/icons.svg#chevron-right"></use>
            </svg>
          </button>
        </div>
      </footer>
    </base-container>
    <swiper
        :slides-per-view="1"
        :modules="modules"
        :autoHeight="true"
        :navigation="{
            nextEl: '.testimonial-sc__button-next',
            prevEl: '.testimonial-sc__button-prev',
          }"
        :space-between="30"
    >
      <swiper-slide v-for="item in items" :key="`${item.meno} ${item.datum}`">
        <base-container>
          <article class="testimonial-wrap" :class="{['dark-pg']: isDarkMode}">
            <div class="stars">
              <svg v-for="index in 5" :key="index" class="testimonial-icon" width="64" height="64">
                <use v-if="index > item.stars" href="../../assets/icons.svg#star-o"></use>
                <use v-else href="../../assets/icons.svg#star"></use>
              </svg>
            </div>
            <p class="testimonial__quote" :class="{['dark-pg']: isDarkMode}">
              {{ item.text }}
            </p>
            <footer class="testimonial__footer">
              <div>
                <h5 class="testimonial__name" :class="{['dark-pg']: isDarkMode}">{{ item.meno }}</h5>
                <div class="testimonial__position">{{ format(item.datum, 'dd.MM.yyyy')}}</div>
              </div>
            </footer>
          </article>
        </base-container>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import {Navigation} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import BaseContainer from '@/components/BaseContainer.vue';
import { format, compareDesc } from 'date-fns';

export default {
  name: 'TestimonialsSection',
  components: {
    BaseContainer,
    Swiper,
    SwiperSlide,
  },
  created () {
    fetch('https://api.boldipuc.sk/api/collections/get/recenzie', {
      headers: {
        'Cockpit-Token': process.env.VUE_APP_TOKEN
      }
    }).then(async response => {
      const body = await response?.json();
      this.items = body?.entries.map(entry => {
        const [day, month, year] = entry.datum.split('.');
        return {...entry, datum: new Date(year, month-1, day)}
      }).sort((entryA, entryB) => compareDesc(entryA.datum, entryB.datum));
    });
  },
  computed: {
    isDarkMode () {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    },
  },
  data () {
    return {
      format,
      modules: [Navigation],
      items: [],
    };
  }
};
</script>

<style scoped lang="scss">

.testimonial-sec, {
  padding-top: 2rem;
  padding-bottom: 2rem;

  &.dark-pg {
    background: var(--gray-900);
  }
}

.stars {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.testimonial-sec__title {
  font-size: 2rem;
  line-height: 1.5;
  color: var(--green-900);

  &.dark-pg {
    color: var(--green-500);
  }
}

.testimonial-wrap {
  padding: 2rem 2.5rem 3rem;
  border-radius: 2rem;
  background-color: var(--white);

  &.dark-pg {
    background-color: var(--gray-800);
  }
}

.testimonial-icon {
  color: var(--green-400);
}

.testimonial__quote {
  font-size: 1rem;
  line-height: 200%;
  margin: 0 auto 2rem;
  color: var(--green-900);

  &.dark-pg {
    color: var(--white);
  }
}

.testimonial__name {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: var(--gray-800);

  &.dark-pg {
    color: var(--green-500);
  }
}

.testimonial__position {
  font-size: 0.875rem;
  line-height: normal;
  color: var(--gray-600);
}

.carousel-nav__button {
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none !important;
  background-color: var(--secondary);
  color: var(--white);
  transition: background-color 0.3 ease;
}

.testimonial-sc__button-prev {
  margin-right: 0.5rem;
}

.swiper-button-disabled {
  background-color: var(--green-300);
}

.testimonial-sec__footer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

@media screen and (min-width: 992px) {
  .testimonial-sec {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .testimonial-sec__title {
    font-size: 2.5rem;
  }

  .testimonial__quote {
    font-size: 1.25rem;
  }

  .testimonial__name {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 560px) {
  .stars {
    svg {
      height: 40px;
      width: 40px;
    }
  }
}
</style>

<template>
  <div class="bubbles">
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="bubble"></div>
    <div class="circle circle-one"></div>
    <div class="circle circle-two"></div>
  </div>
</template>

<style scoped>
.bubbles{
  position:absolute;
  width:100%;
  height: 100%;
  z-index:0;
  overflow:hidden;
  top:0;
  left:0;
  pointer-events: none;
}
.bubble{
  position: absolute;
  bottom:-100px;
  width:80px;
  height: 80px;
  background:#f1f1f1;
  border-radius:50%;
  opacity:0.05;
  animation: rise 18s infinite ease-in;
}
.bubble:nth-child(1){
  width:40px;
  height:40px;
  left:10%;
  animation-duration:8s;
}
.bubble:nth-child(2){
  width:20px;
  height:20px;
  left:20%;
  animation-duration:5s;
  animation-delay:1s;
}
.bubble:nth-child(3){
  width:50px;
  height:50px;
  left:35%;
  animation-duration:7s;
  animation-delay:2s;
}
.bubble:nth-child(4){
  width:80px;
  height:80px;
  left:50%;
  animation-duration:11s;
  animation-delay:0s;
}
.bubble:nth-child(5){
  width:35px;
  height:35px;
  left:55%;
  animation-duration:6s;
  animation-delay:1s;
}
.bubble:nth-child(6){
  width:45px;
  height:45px;
  left:65%;
  animation-duration:8s;
  animation-delay:3s;
}
.bubble:nth-child(7){
  width:90px;
  height:90px;
  left:70%;
  animation-duration:12s;
  animation-delay:2s;
}
.bubble:nth-child(8){
  width:25px;
  height:25px;
  left:80%;
  animation-duration:6s;
  animation-delay:2s;
}
.bubble:nth-child(9){
  width:15px;
  height:15px;
  left:70%;
  animation-duration:5s;
  animation-delay:1s;
}
.bubble:nth-child(10){
  width:190px;
  height:190px;
  left:25%;
  animation-duration:10s;
  animation-delay:4s;
  bottom:-200px;
}
@keyframes rise{
  0%{
    bottom:-200px;
    transform:translateX(0);
  }
  50%{
    transform:translate(100px);
  }
  100%{
    bottom:1080px;
    transform:translateX(-200px);
  }
}

.circle {
  border-radius: 50%;
  position: absolute;
}
.circle-one {
  width: 20rem;
  height: 20rem;
  top: -25%;
  right: 10%;
  background-color: var(--green-700);
  opacity: 0.3;
}

.circle-two {
  width: 15rem;
  height: 15rem;
  bottom: -2%;
  left: -5%;
  background-color: var(--green-700);
  opacity: 0.5;
}
</style>
<script setup>
</script>

<template>
  <section class="hero-price-sec">
    <h1>Galéria</h1>
  </section>
  <section class="gallery-sec" :class="{['dark-pg']: isDarkMode}">
    <base-container>
      <div class="wrapper">
        <Loader/>
        <img
            :alt="image.id"
            :src="prefix+image.thumbs.path"
            v-for="(image, index) in images"
            :key="image.id"
            class="image-thumbnail"
            @click="openPreview(index)"/>
      </div>
    </base-container>
    <div class="preview" :class="{visible: isOpen}">
      <div class="close-button" @click="closePreview()">
        <svg width="24" height="24">
          <use href="../assets/icons.svg#cross"></use>
        </svg>
      </div>
      <div class="preview-image" v-if="isOpen">
        <swiper
            :navigation="true"
            :modules="modules"
            :slides-per-view="1"
            :keyboard="{
              enabled: true,
            }"
            :pagination="{
              clickable: true,
            }"
            :initial-slide="selectedImage"
            virtual
            style="height: 100%; width: 100%"
        >
          <swiper-slide
              v-for="(image, index) in images"
              :key="index"
              :virtualIndex="index"
          >
            <Loader/>
            <img alt="preview" :src="prefix+image?.full?.path"/>
          </swiper-slide
          >
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from '@/components/Loader.vue';
import {Virtual} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/vue';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default {
  name: 'PhotoGallery',
  components: {
    Loader, Swiper,
    SwiperSlide,
  },
  computed: {
    isDarkMode () {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    },
  },
  created () {
    fetch('https://api.boldipuc.sk/api/collections/get/galeria', {
      headers: {
        'Cockpit-Token': process.env.VUE_APP_TOKEN
      }
    }).then(async response => {
      const body = await response?.json();
      this.images = body?.entries?.map(entry => ({id: entry?.image._id, ...entry?.image?.sizes}));
    });
  },
  methods: {
    closePreview () {
      this.isOpen = false;
      this.selectedImage = null;
    },
    openPreview (index) {
      this.selectedImage = index;
      this.isOpen = true;
    }
  },
  data () {
    return {
      modules: [Keyboard, Pagination, Navigation, Virtual],
      selectedImage: 0,
      isOpen: false,
      images: [],
      prefix: process.env.VUE_APP_ASSETS,
    };
  }
};
</script>

<style scoped lang="scss">
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background: var(--primary);
  color: var(--white);
  border-radius: 9px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  z-index: 2;
}

.preview {
  background: rgba(25, 28, 30, 0.66);
  position: fixed;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);

  &:not(.visible) {
    display: none;
  }

  .preview-image {
    height: 100%;
    width: 100%;

    .loader {
      position: absolute;
      height: 200px;
      width: 200px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: -1;
    }

    img {
      padding: 30px;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}

.hero-price-sec {
  background: var(--primary);
  color: var(--white);
  text-align: center;
  padding: 4rem 0 0 0;

  h1 {
    margin: 0;
  }
}

.gallery-sec {
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    z-index: 2;
    position: relative;
    height: 8rem;
    background: url('../assets/curve-blue.svg');
    background-size: 100% 100%;
    margin-left: -50%;
    min-width: 700px;
    margin-right: -50%;
  }

  &::after {
    content: "";
    display: block;
    height: 5rem;
    background: url('../assets/curve-white.svg');
    background-size: 100% 100%;
    margin-left: -50%;
    min-width: 700px;
    margin-right: -50%;
  }

  &.dark-pg {
    background: var(--gray-900);

    &::after {
      background: url('../assets/curve-white-dark.svg');
      background-size: 100% 100%;
    }
  }

  .wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 260px;
  }

  .image-thumbnail {
    width: 25%;
    height: 30vh;
    object-fit: cover;
    cursor: pointer;
    z-index: 1;
  }
}

@media screen and (min-width: 992px) {
  .wrapper {
    padding: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .gallery-sec .image-thumbnail {
    width: 33.33%;
    height: 200px;
    object-fit: cover;
  }
}

@media screen and (max-width: 500px) {
  .gallery-sec .image-thumbnail {
    width: 33.33%;
    height: 150px;
    object-fit: cover;
  }
}
</style>

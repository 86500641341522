<template>
  <section id="contact" class="callout-sec" :class="{['dark-pg']: isDarkMode}">
    <base-container>
      <article class="callout">
        <div class="callout__Content">
          <h2 class="callout__title">
            Máte otázky?
          </h2>
          <p class="callout__desc">
            Ak máte otázky, neváhajte nám zavolať alebo napísať na e-mail
          </p>
          <base-button link href="mailto:info@boldipuc.sk" class="btn--primary btn--large">
            <svg width="24" height="24"><use href="../../assets/icons.svg#mail"></use></svg>
            Napísať
          </base-button>
          <base-button link href="tel:+421-918-198-453" class="btn--primary btn--large">
            <svg width="24" height="24"><use href="../../assets/icons.svg#phone"></use></svg>
            Zavolať
          </base-button>
        </div>
        <Bubbles />
      </article>
    </base-container>
  </section>
</template>
<script>
import Bubbles from '@/components/Bubbles.vue';

export default {
  name: 'CalloutSection',
  components: {Bubbles},
  computed: {
    isDarkMode() {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  }
}
</script>
<style scoped lang="scss">
.callout-sec {
  overflow: hidden;
  padding-top: 2rem;
  &::after {
    content: "";
    display: block;
    height: 5rem;
    background: url('../../assets/curve-white.svg');
    background-size: 100% 100%;
    margin-left: -50%;
    min-width: 700px;
    margin-right: -50%;
  }
  &.dark-pg {
    background: var(--gray-900);
    &::after {
      background: url('../../assets/curve-white-dark.svg');
      background-size: 100% 100%;
    }
  }
}
.callout {
  background-color: var(--primary);
  text-align: center;
  padding: 6rem 1rem;
  border-radius: 2rem;
  color: var(--white);
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}

.callout__title {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.3;
}

.callout__desc {
  max-width: 530px;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--gray-400);
  margin: 0 auto 2.5rem;
}

.callout__Content {
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 992px) {
  .callout-sec {
    padding-top: 4rem;
  }

  .callout {
    margin-bottom: 4rem;
  }

  .callout__title {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: 600;
    line-height: 1.3;
  }

  .callout__desc {
    max-width: 530px;
    font-size: 1.125rem;
    line-height: 1.5;
    color: var(--gray-400);
    margin: 0 auto 2.5rem;
  }
}
.btn--large svg {
  margin: -5px 0;
}
</style>

<template>
  <the-header></the-header>
  <router-view/>
  <the-footer></the-footer>
</template>

<script>
import TheHeader from './components/TheHeader';
import TheFooter from './components/TheFooter.vue';

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter
  },
}
</script>

<style>
@import '~normalize.css/normalize.css';
@import './styles/global.css';
</style>

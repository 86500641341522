<template>
  <section class="about-sec" :class="{['dark-pg']: isDarkMode}">
    <base-container>
      <div class="about-card">
        <div class="row">
          <div class="col--12 col--lg-6">
            <figure class="about-card__figure">
              <img src="../../assets/denis-niki.svg" alt="Denis a Niki">
            </figure>
          </div>
          <div class="col--12 col--lg-6">
            <div class="about-card__text">
              <h2 class="about-card__title" :class="{['dark-pg']: isDarkMode}">Kto sme?</h2>
              <p class="about-card__desc">
                Ahoj, sme Niki a Denis, manželia a spoluzakladatelia firmy Boldipuc. Po skončení štúdia sme sa rozhodli
                spoločne podnikať. Mali sme spoločnú vášeň pre upratovanie a ekológiu, tak sme sa rozhodli založiť
                upratovaciu firmu, ktorá ponúka kvalitné a ekologické služby pre domácnosti aj firmy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="about-card">
        <div class="row">
          <div class="col--12 col--lg-6">
            <div class="about-card__text">
              <h2 class="about-card__title" :class="{['dark-pg']: isDarkMode}">Čo robíme?</h2>
              <p class="about-card__desc">
                Naša firma sa špecializuje na profesionálne upratovanie domov, kancelárií a prevádzok. Sme tím milých a
                spoľahlivých pracovníkov, ktorí sa starajú o čistotu a pohodu vašich priestorov. Sme tu pre vás, aby sme
                vám uľahčili starosti s upratovaním a priniesli vám úsmev na tvári.
              </p>
            </div>
          </div>
          <div class="col--12 col--lg-6">
            <figure class="about-card__figure">
              <img src="../../assets/niki-denis-clean.svg" alt="people climbing">
            </figure>
          </div>
        </div>
      </div>
    </base-container>
  </section>
</template>

<script>
export default {
  name: 'AboutSection',
  computed: {
    isDarkMode() {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  }
}
</script>

<style scoped lang="scss">
img {
  max-height: 350px;
}

.about-sec {
  padding-top: 3rem;
  padding-bottom: 1rem;
  &.dark-pg {
    background: var(--gray-900);
    color: var(--white);
  }
}

.about-card {
  margin-bottom: 3rem;
}

.about-card__text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-card__title {
  text-align: left;
  font-size: 2rem;
  color: var(--green-900);
  margin-bottom: 2rem;
  &.dark-pg {
    color: var(--green-700);
  }
}

.about-card__desc {
  font-size: 1rem;
  line-height: 200%;
}

.about-card__figure {
  margin-bottom: 1.5rem;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .about-sec {
    padding-top: 5rem;
  }
  .about-card__figure {
    margin-bottom: 0;
  }

  .about-card__title {
    font-size: 2.5rem;
  }

  .about-card__desc {
    font-size: 1.25rem;
  }
}
</style>

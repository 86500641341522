<template>
  <hero-section></hero-section>
  <about-section></about-section>
  <values-section></values-section>
  <services-section></services-section>
  <testimonials-section></testimonials-section>
  <callout-section></callout-section>
<!--  <posts-section></posts-section>-->
<!--  <newsletter-section></newsletter-section>-->
</template>

<script>
import AboutSection from '../pages/home/AboutSection';
import ValuesSection from '../pages/home/ValuesSection';
import ServicesSection from '../pages/home/ServicesSection';
import TestimonialsSection from '../pages/home/TestimonialsSection';
import CalloutSection from '../pages/home/CalloutSection';
import HeroSection from '@/pages/home/HeroSection.vue';
// import PostsSection from '../pages/home/PostsSection';
// import NewsletterSection from '../pages/home/NewsletterSection.vue';

export default {
  name: 'App',
  mounted () {
    if (location.hash === '#kontakt') {
      const el = document.getElementById('contact')
      if (el) {
        setTimeout(() => {
          el.scrollIntoView({ block: "center",behavior: 'smooth', inline: "center" });
        }, 1000)
      }
    }
  },
  components: {
    HeroSection,
    AboutSection,
    ValuesSection,
    ServicesSection,
    TestimonialsSection,
    CalloutSection,
    // PostsSection,
    // NewsletterSection,
  }
}
</script>

<style>
@import '~normalize.css/normalize.css';
@import '../styles/global.css';
</style>

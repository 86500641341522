<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="loader" viewBox="0 0 300 150" height="200" width="200">
    <path class="svg__stroke svg_strokeWidth" fill="none" stroke-width="28" stroke-linecap="round"
          stroke-dasharray="300 385" stroke-dashoffset="0"
          style="stroke: var(--green-500);"
          d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z">
      <animate attributeName="stroke-dashoffset" calcMode="spline" dur="2.5" values="685;-685" keySplines="0 0 1 1"
               repeatCount="indefinite"></animate>
    </path>
  </svg>
</template>

<!--<script>-->
<!--export default {-->
<!--  props: {-->
<!--    link: {-->
<!--      type: Boolean,-->
<!--      required: false,-->
<!--      default: false-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->

<style scoped>
.loader {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>

<template>
  <section class="services-sec" :class="{['dark-pg']: isDarkMode}">
    <div class="services-sec__inner">
      <base-container>
        <h2 class="services-sec__title" :class="{['dark-pg']: isDarkMode}">Naše služby</h2>
        <div class="row">
          <div class="col--12 col--lg-6">
            <article class="service-card" :class="{['dark-pg']: isDarkMode}">
              <div class="service-card__icon service-card__icon--green">
                <svg width="55" height="55">
                  <use href="../../assets/icons.svg#housecleaning"></use>
                </svg>
              </div>
              <h3 class="service-card__title" :class="{['dark-pg']: isDarkMode}">Upratovanie</h3>
              <p class="service-card__desc" :class="{['dark-pg']: isDarkMode}">
                <ul>
                  <li><strong>Upratovanie domov:</strong> Ponúkame pravidelné alebo jednorazové upratovanie bytov, domov, chát a chalúp. Zabezpečujeme vysávanie, utieranie prachu, umývanie podláh, čistenie kúpeľní, kuchýň a ďalších miestností</li>
                  <li><strong>Upratovanie kancelárií:</strong> Ponúkame pravidelné alebo jednorazové upratovanie kancelárskych priestorov, zasadačiek, recepcií, toaliet a ďalších častí. Zabezpečujeme vysávanie, utieranie prachu, umývanie podláh, čistenie okien, dverí, nábytku a ďalších prvkov</li>
                      <li><strong>Upratovanie prevádzok:</strong> Ponúkame pravidelné alebo jednorazové upratovanie prevádzok, ako sú reštaurácie, bary, kaviarne, hotely, obchody, salóny a ďalšie. Zabezpečujeme vysávanie, utieranie prachu, umývanie podláh, čistenie okien, dverí, nábytku, zariadenia a ďalších prvkov</li>
                </ul>
              </p>
            </article>
          </div>
          <div class="col--12 col--lg-6">
            <article class="service-card" :class="{['dark-pg']: isDarkMode}">
              <div class="service-card__icon service-card__icon--red">
                <svg width="55" height="55">
                  <use href="../../assets/icons.svg#tepping"></use>
                </svg>
              </div>
              <h3 class="service-card__title" :class="{['dark-pg']: isDarkMode}">Tepovanie</h3>
              <p class="service-card__desc" :class="{['dark-pg']: isDarkMode}">
                <ul>
                  <li><strong>Tepovanie sedačiek:</strong> Ponúkame hĺbkové tepovanie sedačiek, kresiel, kancelárskych stoličiek, taburetiek a vankúšov</li>
                  <li><strong>Tepovanie kobercov:</strong> Účinne čistíme kancelárske koberce aj kusové koberce</li>
                  <li><strong>Tepovanie matracov:</strong> Kompletné tepovanie matracov a postelí pre zdravý a čistý spánok</li>
                  <li><strong>Čalúneného nábytku:</strong> Poskytujeme hĺbkové tepovanie čalúneneho nábytku, vrátane sedačiek, kresiel, gaučov a ďalších čalúnených prvkov.</li>
                </ul>
              </p>
            </article>
          </div>
          <div class="col--12 col--lg-6">
            <article class="service-card" :class="{['dark-pg']: isDarkMode}">
              <div class="service-card__icon service-card__icon--blue">
                <svg width="55" height="55">
                  <use href="../../assets/icons.svg#cleaning"></use>
                </svg>
              </div>
              <h3 class="service-card__title" :class="{['dark-pg']: isDarkMode}">Umývanie</h3>
              <p class="service-card__desc" :class="{['dark-pg']: isDarkMode}">
                <ul>
                  <li><strong>Umytie okien:</strong> Ponúkame komplexné umývanie okien na domoch, bytoch, kanceláriách a ďalších objektoch</li>
                  <li><strong>Čistenie rámov a parapetov:</strong> Okrem umývania okien sa staráme aj o čistenie rámov a parapetov, aby vaše okná vyzerali ako nové</li>
                  <li><strong>Umyvanie žalúzií a roliet:</strong> Zabezpečujeme dôkladné umývanie žalúzií a roliet, aby ste mali nielen čisté okná, ale aj tieniaci systém vo vynikajúcom stave</li>
                </ul>
              </p>
            </article>
          </div>
          <div class="col--12 col--lg-6">
            <article class="service-card" :class="{['dark-pg']: isDarkMode}">
              <div class="service-card__icon service-card__icon--orange">
                <svg width="55" height="55">
                  <use href="../../assets/icons.svg#man"></use>
                </svg>
              </div>
              <h3 class="service-card__title" :class="{['dark-pg']: isDarkMode}">Hodinový manžel</h3>
              <p class="service-card__desc" :class="{['dark-pg']: isDarkMode}">
                <ul>
                  <li><strong>Montáž/demontáž nábytku:</strong>Zahrňuje zmontovanie a rozoberanie nábytku, napríklad počas presťahovania alebo údržby</li>
                  <li><strong>Menšie prerábky bytov:</strong> Ide o drobné úpravy v byte, ako napríklad výmena zásuviek, oprava dier v stenách alebo iné drobné zmeny</li>
                  <li><strong>Nákupy materiálu a tovaru:</strong> Zabezpečuje nákup potrebného materiálu a tovaru na rôzne projekty</li>
                  <li><strong>Maľovanie:</strong> Zahrnuje maľovanie stien, stropov alebo iných povrchov v byte alebo dome</li>
                  <li><strong>Malé vodoinštalácie:</strong> Týka sa drobných opráv alebo úprav vodoinštalácií, ako napríklad oprava kohútika alebo výmena tesnenia</li>
                  <li><strong>A iné:</strong>Zahŕňa rôzne úlohy alebo práce, ktoré nie sú uvedené v zozname, ale môžu byť dohodnuté individuálne s klientom</li>
                </ul>
              </p>
            </article>
          </div>
        </div>
      </base-container>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ServicesSection',
  computed: {
    isDarkMode() {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  }
}
</script>
<style scoped lang="scss">
.services-sec {
  background-color: var(--white);
  &.dark-pg {
    color: white;
    background: var(--black);
    &::before, &::after {
      background: url("../../assets/curve-gray-dark.svg") center bottom/cover no-repeat;
    }
  }
}

ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

strong {
  display: block;
  color: var(--green-800);
}

.services-sec__inner {
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.services-sec::before,
.services-sec::after {
  content: "";
  display: block;
  height: 3rem;
  width: 100%;
}

.services-sec::before {
  top: 0;
  background: url("../../assets/curve-gray.svg") center bottom/cover no-repeat;
}

.services-sec::after {
  bottom: 0;
  background: url("../../assets/curve-gray.svg") center bottom/cover no-repeat;
  transform: rotate(180deg);
}

.services-sec__title {
  text-align: center;
  font-size: 2rem;
  line-height: 1.5;
  color: var(--green-900);
  margin-bottom: 3.5rem;
  &.dark-pg {
    color: var(--green-500);
  }
}

.service-card {
  padding: 3rem 2rem;
  border-radius: 1rem;
  background-color: var(--gray);
  text-align: left;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.5rem;
  flex-grow: 1;
  &.dark-pg {
    background: var(--gray-900);
  }
}

.service-card__title {
  font-size: 1.125rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: var(--green-800);
  &.dark-pg {
    color: var(--green-500);
  }
}

.service-card__desc {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--gray-700);
  &.dark-pg {
    color: white;
    strong {
      color: var(--green-500);
    }
  }
}

.service-card__icon {
  margin-bottom: 1.5rem;
  color: var(--green-500);
}

.row .col--12.col--lg-6 {
  display: flex;
}

@media screen and (min-width: 992px) {

  .services-sec__inner {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }

  .services-sec::before,
  .services-sec::after {
    height: 5rem;
  }

  .services-sec__title {
    font-size: 2.5rem;
  }

  .service-card__title {
    font-size: 1.5rem;
  }

  .service-card__desc {
    font-size: 1.125rem;
  }
}
</style>
<script setup>
</script>

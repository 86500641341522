<template>
  <footer class="footer" :class="{['dark-pg']: isDarkMode}">
    <div class="footer__inner" :class="{['dark-pg']: isDarkMode}">
      <base-container>
        <ul class="list list--inline footer__nav">
          <li class="nav__item">
            <router-link class="nav__link" :to="{name: 'Home'}">Domov</router-link>
          </li>
          <li class="nav__item">
            <router-link class="nav__link" :to="{name: 'Pricelist'}">Cenník</router-link>
          </li>
          <li class="nav__item">
            <router-link class="nav__link" :to="{name: 'Gallery'}" >Galéria</router-link>
          </li>
          <li class="nav__item">
            <router-link @click="onContactClick()" class="nav__link" :to="{name: 'Home', hash: '#kontakt'}">Kontakt</router-link>
          </li>
        </ul>
        <ul class="list list--inline socilas">
          <li class="social__item">
            <a class="socila__link" href="https://www.facebook.com/profile.php?id=61555382745995" target="_blank">
              <svg width="24" height="24">
                <use href="../assets/icons.svg#facebook"></use>
              </svg>
            </a>
          </li>
          <li class="social__item">
            <a class="socila__link" href="https://www.instagram.com/boldi.puc" target="_blank">
              <svg width="24" height="24">
                <use href="../assets/icons.svg#instagram"></use>
              </svg>
            </a>
          </li>
        </ul>
        <p class="copyright">&copy;2024 Všetky práva vyhradené.</p>
      </base-container>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'TheFooter',
  computed: {
    isDarkMode() {
      return (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    }
  },
  methods: {
    onContactClick() {
      const el = document.getElementById('contact')
      if (el) {
        setTimeout(() => {
          console.log(el);
          el.scrollIntoView({ behavior: 'smooth',block: "center", inline: "center" });
        }, 0)      }
    },
  }
}
</script>
<style scoped lang="scss">
.footer {
  position: relative;
}

.footer__inner {
  padding-bottom: 1.5rem;
  background: var(--white);
  &.dark-pg {
    background: var(--gray-800);
  }
}

.footer__nav {
  justify-content: center;
  margin-bottom: 3rem;
}

.nav__link {
  color: var(--green-700);
  font-size: 1rem;
  padding: 0.5rem;
  text-decoration: none !important;
}
.dark-pg {
  .nav__link {
    color: var(--green-500);
  }
  .socila__link {
    color: var(--white);
    background: var(--green-500);
  }
}

.socilas {
  justify-content: center;
  margin-bottom: 2rem;
}

.social__item {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.socila__link {
  color: var(--white);
  padding: 0.75rem;
  background: var(--primary);
  border-radius: 50%;
  display: flex;
}

.copyright {
  font-size: 0.875rem;
  text-align: center;
  color: var(--white);
}

@media screen and (min-width: 768px) {
  .footer::before {
    height: 10rem;
  }

  .nav__link {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
  }

  .socila__link {
    padding: 1rem;
  }

  .socila__link:hover {
    background-color: var(--primary);
    color: var(--white);
    transition: background-color 0.3s ease;
  }

}
</style>

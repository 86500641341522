<template>
  <section class="hero-price-sec">
    <h1>Cenník</h1>
  </section>
  <section class="price-sec" :class="{['dark-pg']: isDarkMode}">
    <base-container>
      <div>
        <h2>Upratovanie domácností pravidelné</h2>
        <table>
          <tr>
            <td>1izbovy byt</td>
            <td>od 30€</td>
          </tr>
          <tr>
            <td>2izbovy byt</td>
            <td>od 40€</td>
          </tr>
          <tr>
            <td>3izbovy byt</td>
            <td>od 50€</td>
          </tr>
          <tr>
            <td>4izbovy byt</td>
            <td>od 55€</td>
          </tr>
          <tr>
            <td>Byt/dom nad 100m2</td>
            <td>od 60€</td>
          </tr>
          <tr>
            <td colspan="2">
              <p>Pravidelné upratovanie zahŕňa:</p>
              <ul>
                <li>Vysávanie kobercov a podláh</li>
                <li>Umývanie podláh a obkladov</li>
                <li>Dezinfikacia a čistenie kupeľne a toalety</li>
                <li>Vyčistenie kuchynskej linky, drezu a baterií</li>
                <li>Umytie mikrovlnnej rúry zvonku</li>
                <li>Umytie elektrickej rúry zvonku</li>
                <li>Umytie chladničky a mrazničky zvonku</li>
                <li>Vysypanie smetného koša</li>
                <li>Utieranie prachu</li>
              </ul>
            </td>
          </tr>
        </table>
        <h2>Upratovanie domácností jednorazové/hĺbkové</h2>
        <table>
          <tr>
            <td>1izbovy byt</td>
            <td>od 50€</td>
          </tr>
          <tr>
            <td>2izbovy byt</td>
            <td>od 70€</td>
          </tr>
          <tr>
            <td>3izbovy byt</td>
            <td>od 80€</td>
          </tr>
          <tr>
            <td>4izbovy byt</td>
            <td>od 90€</td>
          </tr>
          <tr>
            <td>Byt/dom nad 100m2</td>
            <td>od 100€</td>
          </tr>
          <tr>
            <td colspan="2">
              <p>Jednorazové/hĺbkové upratovanie zahŕňa:</p>
              <ul>
                <li>Vysávanie kobercov a podláh</li>
                <li>Umývanie podláh a obkladov</li>
                <li>Dezinfikacia, čistenie kupeľne, toalety a odstránenie vodného kameňa</li>
                <li>Vyčistenie kuchynskej linky, drezu a baterií z vnútra</li>
                <li>Umytie mikrovlnnej rúry z vnútra</li>
                <li>Umytie elektrickej rúry z vnútra</li>
                <li>Umytie chladničky a mrazničky z vnútra</li>
                <li>Vysypanie smetného koša</li>
                <li>Utieranie prachu</li>
              </ul>
            </td>
          </tr>
        </table>

        <h4>Upratovanie
          Kancelárie, administratívne priestory, ambulancie, kliniky, nebytové priestory, autoservisy, bytovky cena na
          vyžiadanie po obhliadke/telefonickom dohovore.<br>
          Postavebné upratovanie cena na vyžiadanie.</h4>
        <h2>Umývanie okien a sklenených plôch</h2>
        <h3>Obojstranné umývanie okien vrátane rámov a parapiet.</h3>
        <table>
          <tr>
            <td>Bežné okno</td>
            <td>10€</td>
          </tr>
          <tr>
            <td>Francúzske okno</td>
            <td>13€</td>
          </tr>
          <tr>
            <td>Balkónové dvere</td>
            <td>13€</td>
          </tr>
          <tr>
            <td>Žalúzie a rolety</td>
            <td>od 3€</td>
          </tr>
          <tr>
            <td>Sieťky</td>
            <td>5€</td>
          </tr>
        </table>
        <h3>Tepovanie</h3>
        <p>Cena tepovania záleží od rozmerov a počtu miest na sedenie</p>
        <br>
        <table>
          <tr>
            <td>Dvojmiestna sedačka</td>
            <td>30€</td>
          </tr>
          <tr>
            <td>Trojmiestna sedačka</td>
            <td>40€</td>
          </tr>
          <tr>
            <td>Rohová sedačka</td>
            <td>od 50€</td>
          </tr>
          <tr>
            <td>Kreslo</td>
            <td>od 20€</td>
          </tr>
          <tr>
            <td>Stolička</td>
            <td>od 5€</td>
          </tr>
          <tr>
            <td>Koberec</td>
            <td>od 15€</td>
          </tr>
          <tr>
            <td>Matrac</td>
            <td>od 25€</td>
          </tr>
        </table>
        <h3>Umývanie auta</h3>
        <table> <tr> <td>Interiér/exteriér</td> <td>od 30€</td> </tr> </table>
        <br>
        <ul>
          <li><strong>V cene upratovania</strong> sú zahrnuté všetky prostriedky a príslušenstvo na upratovanie.</li>
          <li>Použitie <strong>parného čističa na vyžiadanie</strong>.</li>
          <li><strong>Služby hodinového manžela</strong>: Od 15 €/hod.</li>
          <li>Práce realizujeme v Bratislave a 20 km v okolí Bratislavy.</li>
          <li>Pri pravidelnom upratovaní je doprava v cene. <strong>Jednorazové upratovanie</strong> alebo využitie našich služieb má cenu dopravy 5 €.</li>
        </ul>
      </div>
    </base-container>
  </section>
</template>

<script>

export default {
  name: 'PriceList',
  components: {},
  computed: {
    isDarkMode () {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  }
};
</script>

<style scoped lang="scss">
.hero-price-sec {
  background: var(--primary);
  color: var(--white);
  text-align: center;
  padding: 4rem 0 0 0;
  h1 {
    margin: 0;
  }
}

.price-sec {
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    z-index: 2;
    position: relative;
    height: 8rem;
    background: url('../assets/curve-blue.svg');
    background-size: 100% 100%;
    margin-left: -50%;
    min-width: 700px;
    margin-right: -50%;
  }
  &::after {
    content: "";
    display: block;
    height: 5rem;
    background: url('../assets/curve-white.svg');
    background-size: 100% 100%;
    margin-left: -50%;
    min-width: 700px;
    margin-right: -50%;
  }
  &.dark-pg {
    background: var(--gray-900);
    &::after {
      background: url('../assets/curve-white-dark.svg');
      background-size: 100% 100%;
    }
  }
  &.dark-pg {
    h2, h3 {
      color: var(--green-500)
    }
    h4, li, p {
      color: var(--white)
    }
  }
  &.dark-pg table tr {
    background: var(--gray-700);
    color: var(--white);
    &:has(li) {
      background: var(--gray-900);
    }
  }

  div {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border-radius: 9px;
    overflow: hidden;

    tr {
      &:has(li) {
        background: var(--white);
      }

      td {
        padding: 16px 20px;

        &:nth-child(2) {
          text-align: end;
        }
      }

      &:nth-child(odd) {
        background: rgba(12, 89, 13, 0.27)
      }
    }
  }
}

h2, h3 {
  margin: 30px 0 10px 0;
}
h4 {
  margin-top: 10px;
}
@media screen and (min-width: 992px) {
  .price-sec div {
    padding: 2rem;
  }
}
</style>

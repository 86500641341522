<template>
  <section class="hero-sec" :class="{['dark-pg']: isDarkMode}">
    <div class="hero-sec__inner">
      <base-container>
        <div class="row">
          <div class="hero-sec-logo">
            <img v-if="isDarkMode" height="350px" src="../../assets/boldipuc_logo_full_dark.svg"/>
            <img v-else height="350px" src="../../assets/boldipuc_logo_full.svg"/>
          </div>
          <div>
            <h1>Vaša špina,<br>naša radosť</h1>
          </div>
        </div>
      </base-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
  computed: {
    isDarkMode () {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  }
};
</script>

<style scoped lang="scss">
.hero-sec-logo {
  background: var(--white);
  padding: 40px;
  border-radius: 20px;
}

.hero-sec {
  color: var(--white);
  position: relative;

  .row {
    align-items: center;
    gap: 20px;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-size: 70px;
      text-align: center;
    }
  }

  &.dark-pg {
    background: var(--gray-900);

    .hero-sec-logo {
      background: var(--gray-800);
    }
  }
}

.hero-sec__inner {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--primary);
}

.hero-sec::after {
  content: "";
  display: block;
  height: 8rem;
  background: url('../../assets/curve-blue.svg') bottom/cover no-repeat;
}


@media screen and (min-width: 992px) {

  .hero-sec__inner {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 5rem);

    .row {
      h1 {
        text-align: left;
      }
      flex-direction: row;
    }
  }

  .hero-sec::after {
    height: 13rem;
  }
}

@media screen and (max-width: 560px) {

  .hero-sec__inner {
    .row {
      img {
        height: 160px;
      }
      h1 {
        font-size: 50px;
      }
    }
  }

  .hero-sec::after {
    height: 13rem;
  }
}
</style>

<template>
  <section class="values-sec" :class="{['dark-pg']: isDarkMode}">
    <base-container>
      <header class="values-sec__header">
        <h2 class="values-sec__title">Naše hodnoty</h2>
        <Bubbles />
      </header>
      <div class="values-sec__cards">
        <div class="row">
          <div v-for="{icon, title, text} in sections" class="col--12 col--lg-4" :key="title">
            <article class="value-card" :class="{['dark-pg']: isDarkMode}">
              <div class="value-card__icon">
                <svg width="48" height="48">
                  <use :href="require('../../assets/icons.svg')+'#'+icon"></use>
                </svg>
              </div>
              <h3 class="value-card__title" :class="{['dark-pg']: isDarkMode}">{{title}}</h3>
              <p class="value-card__desc" :class="{['dark-pg']: isDarkMode}">
                {{text}}
              </p>
            </article>
          </div>
        </div>
      </div>
    </base-container>
  </section>
</template>
<script>
import Bubbles from '@/components/Bubbles.vue';

export default {
  name: 'ValuesSection',
  components: {Bubbles},
  computed: {
    isDarkMode() {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  },
  data() {
    return {
      sections: [
        {icon: 'star-x', text:'Naša firma sa zameriava na profesionálnu prácu a spokojnosť zákazníkov.', title: 'Kvalita'},
        {icon: 'lightning', text:'Prispôsobujeme sa vašim potrebám, požiadavkám a termínom. Ponúkame rôzne frekvencie a rozsahy upratovania podľa vašich predstáv.', title: 'Nekonečná flexibilita'},
        {icon: 'fire', text:'Staráme o kvalitu našich služieb, spĺňame požiadavky, termíny a očakávania našich zákazníkov', title: 'Zodpovednosť'},
      ]
    }
  }
}
</script>
<style scoped lang="scss">
.values-sec {
  padding-bottom: 1rem;
  &.dark-pg {
    background: var(--gray-900);
    color: var(--white)!important;
  }
}

.values-sec__header {
  position: relative;
  background-color: var(--primary);
  padding: 5rem 1rem 14rem;
  color: var(--white);
  text-align: center;
  margin-right: -15px;
  margin-left: -15px;
}

.values-sec__title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.values-sec__desc {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.values-sec__cards {
  margin-top: -10rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.value-card {
  padding: 3rem 2rem;
  border-radius: 1rem;
  background-color: var(--white);
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
  &.dark-pg {
    background: var(--gray-800);
  }
}

.value-card__title {
  font-size: 1.125rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: var(--green-900);
  &.dark-pg {
    color: var(--green-500);
  }
}

.value-card__desc {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--gray-700);
  &.dark-pg {
    color: var(--white);
  }
}

.value-card__icon {
  margin-bottom: 1.5rem;
  color: var(--secondary);
}

@media screen and (min-width: 992px) {
  .values-sec {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }

  .values-sec__header {
    border-radius: 2rem;
    padding: 5rem 1rem 14rem;
    margin: 0;
  }

  .values-sec__title {
    font-size: 3.5rem;
  }

  .values-sec__desc {
    font-size: 1.25rem;
  }

  .values-sec__cards {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
</style>

import {createApp} from 'vue';
import App from './App.vue';
import BaseContainer from './components/BaseContainer.vue';
import BaseButton from './components/BaseButton';
import {createRouter, createWebHistory} from 'vue-router';
import Home from '@/pages/Home.vue';
import Pricelist from '@/pages/Pricelist.vue';
import Gallery from '@/pages/Gallery.vue';

const routes = [
  {path: '/', component: Home, name: 'Home'},
  {path: '/cennik', component: Pricelist, name: 'Pricelist'},
  {path: '/galeria', component: Gallery, name: 'Gallery'},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior:(route) => (route.hash === '#kontant' ? null : {top: 0, behavior: 'smooth'}),
});

const app = createApp(App);
app.component('base-container', BaseContainer);
app.component('base-button', BaseButton);

app.use(router);
app.mount('#app');

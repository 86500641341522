<template>
  <header class="navbar" :class="{['dark-pg']: isDarkMode}">
    <base-container>
      <div class="navbar__inner">
        <router-link class="navbar__brand" :to="{name: 'Home'}">
          <img v-if="isDarkMode" height="40px" src="../assets/boldipuc_logo_landscape.svg" alt="Boldipuc logo">
          <img v-else height="40px" src="../assets/boldipuc_logo_landscape_dark.svg" alt="Boldipuc logo">
        </router-link>
        <div class="navbar__collapse" :class="{ collapsed: navbarCollapsed }">
          <a class="navbar__toggle navbar__toggle--close" v-on:click="hideNavbar">
            <svg width="24" height="24"><use href="../assets/icons.svg#cross"></use></svg>
          </a>
          <ul class="navbar__nav">
            <li class="nav__item" :class="{['dark-pg']: isDarkMode,active: pathName === 'Home'}" >
              <router-link @click="onLinkClick()" class="nav__link" :to="{name: 'Home'}">Domov</router-link>
            </li>
            <li class="nav__item" :class="{['dark-pg']: isDarkMode,active: pathName === 'Pricelist'}">
              <router-link @click="onLinkClick()" class="nav__link" :to="{name: 'Pricelist'}">Cenník</router-link>
            </li>
            <li class="nav__item" :class="{['dark-pg']: isDarkMode,active: pathName === 'Gallery'}">
              <router-link @click="onLinkClick()" class="nav__link" :to="{name: 'Gallery'}">Galéria</router-link>
            </li>
            <li class="nav__item">
              <router-link @click="onContactClick()" class="nav__link" :to="{name: 'Home', hash: '#kontakt'}">Kontakt</router-link>
            </li>
          </ul>
        </div>
        <a class="navbar__toggle" v-on:click="showNavbar">
          <svg width="24" height="24"><use href="../assets/icons.svg#menu"></use></svg>
        </a>
      </div>
    </base-container>
  </header>
</template>

<script>
export default {
  data() {
    return {
      navbarCollapsed: true,
    }
  },
  computed: {
    isDarkMode() {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    },
    pathName() {
      return this.$route?.name;
    }
  },
  methods: {
    showNavbar() {
      this.navbarCollapsed = false;
    },
    hideNavbar() {
      this.navbarCollapsed = true;
    },
    onContactClick() {
      const el = document.getElementById('contact')
      if (el) {
        setTimeout(() => {
          el.scrollIntoView({ block: "center",behavior: 'smooth', inline: "center" });
        }, 0)
      }
      this.hideNavbar()
    },
    onLinkClick() {
      this.hideNavbar()
    }
  }
}
</script>

<style scoped lang="scss">
.navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: var(--white);
  position: sticky;
  top: 0;
  box-shadow: var(--shadow);
  &.dark-pg {
    .navbar__toggle {
      color: var(--white);
    }
    .navbar__brand {
      color: var(--white);
      span {
        color: var(--green-600)
      }
    }
    background: var(--gray-800);
    .nav__item {
      color: var(--white);
    }
  }
}
.navbar__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar__nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav__item {
  padding: 0.5rem 1rem;
}
.navbar__brand,
.nav__link {
  text-decoration: none;
  color: inherit;
}

.nav__link {
  font-size: 2rem;
  color: var(--white);
  font-weight: 400;
}

.navbar__brand {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: sans-serif;
  span {
    color: var(--primary)
  }
}
.navbar__toggle {
  display: flex;
  cursor: pointer;
}

.navbar__toggle--close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: var(--white);
}

@supports (background-color:color-mix(in srgb, var(--primary) 80%, transparent)) {
  .navbar__collapse {
    background-color: color-mix(in srgb, var(--primary) 80%, transparent);
  }
}
.navbar__collapse {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  transform: translateX(0);
  transition: transform 0.4s ease-out;
  padding-top: 6rem;
  backdrop-filter: blur(20px);
}

.navbar__collapse.collapsed {
  transform: translateX(100%);
}

@media screen and (min-width: 768px) {

  .navbar__toggle {
    display: none;
  }

  .navbar__collapse {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    background-color: transparent;
    padding-top: 0;
    transform: none !important;
  }

  .navbar__nav {
    flex-direction: row;
  }

  .nav__item.active {
    position: relative;
  }

  .nav__item.dark-pg.active::after {
    content: "";
    display: block;
    height: 2px;
    background-color: var(--white);
    position: absolute;
    bottom: -4px;
    left: 1rem;
    right: 1rem;
  }
  .nav__item.active::after {
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    bottom: -4px;
    left: 1rem;
    right: 1rem;
    background-color: var(--gray-900);
  }

  .nav__link {
    font-size: 1.125rem;
    color: inherit
  }
}
</style>
